<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :hide-search="false"
        :search-tips="$t('device_env.search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from "@/common";
import TableContent from "@/components/TableContent";
import TableHeader from "@/components/TableHeader";
import TableSearch from "@/components/TableSearch";
import PageContent from "@/components/PageContent";

export default {
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  data() {
    return {
      title: common.getMenuName('bedexitenvironment'),
      // is_admin: common.isAdmin(),
      view_permission: common.checkPermission('Mon_Menu_Environment'),
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage()
      },
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'device_env.mac',
          field: 'deviceMAC',
          // width: '180px',
          changeHTML: (value, row) => `<span class="wh-font-family-monospace">${value}</span>`
        },
        {
          label: 'device_env.env',
          field: 'isUAT',
          change: function (value) {
            switch (value) {
              case true: return common.getI18n('device_env.env_uat');
              case false: return common.getI18n('device_env.env_production');
              case null: return '';
            }
          }
        },
        {
          label: 'common.action',
          field: 'action',
          width: '200px',
          sortable: false,
        },
      ],
      header_actions: [
        {
          text: 'common.export_device_list',
          variant: 'primary',
          fun: this.exportAllData,
          permission: 'Mon_Menu_Environment'
        },
        {
          text: 'common.add',
          variant: 'primary',
          fun: this.addData,
          permission: 'Mon_Menu_Environment'
        },
      ],
      table_actions: [
        {
          text: 'common.edit',
          variant: 'primary',
          fun: 'editData',
          permission: 'Mon_Menu_Environment'
        },
        {
          text: 'common.delete',
          variant: 'danger',
          fun: 'deleteData',
          permission: 'Mon_Menu_Environment'
        }
      ],
      search_fields: [
        {
          field: 'isUAT',
          label: 'device_env.env',
          type: 'select',
          options: [
            {text: common.getI18n('device_env.env_uat'), value: true},
            {text: common.getI18n('device_env.env_production'), value: false},
          ]
        }


      ],
      search_default: {

      }
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    getList: function () {
      if (!this.view_permission) return

      const api = '/bemonitor/GetMonitorList'
      const search = this.getSearchCondition()
      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)

      common.apiGetData(url).then(res => {
        this.total_rows = res.data.total
        this.rows = res.data.monitors
      })
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },
    exportAllData() {
      common.apiDownloadData({
        url: '/bemonitor/ExportMonitorList',
      })
    },
    addData: function () {
      this.$router.push({name: 'bedexitenvironment_add'})
    },
    editData: function (data) {
      if (data.isUAT == null) {
        data.isUAT = ' '
      }

      this.$router.push({name: 'bedexitenvironment_edit', params: {mac: common.encrypt(data.deviceMAC), env: common.encrypt(data.isUAT)}})
    },
    deleteData: function (data) {
      const that = this

      common.showConfirm(this.$t('common.delete_tips')+data.deviceMAC, '', function () {
        common.apiPostData('/bemonitor/DeleteMonitor?deviceMAC='+data.deviceMAC).then(res => {
          common.showToast(that.$t('common.delete_success'))
          that.getList()
        })

      })
    },
    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    searchChangeField: function (field, value) {
    },
  }
}
</script>
